import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import {MapContainer, Marker, Popup, TileLayer} from "react-leaflet";
import 'leaflet/dist/leaflet.css'
import SectionTitle from "./SectionTitle";
import ReactEcharts from "echarts-for-react";
import '../assets/css/flux.css'

const FluxContent = () => {
    return (
        <div className="container about-list-items mb-5">
            <SectionTitle pageTitle="sankey"/>
            <div className="mt-2">
                <div className="col-lg-12 col-xl-12 col-md-12 col-xs-12 flux-panel">
                    <div className="mb-3 p-2 card flux-panel-content">
                        <div className="card-body">
                            <p>Coming soon</p>
                        </div>

                    </div>
                </div>
            </div>
        </div>


    );
};

export default FluxContent;