import axios from 'axios'


//http://siebck.ecosysb.ecowas.int/
//http://localhost/siebck/
/*export const endpointBck = 'http://localhost/siebck/';
export const endpointFrt = 'http://localhost/:3001/';
export const endpoint = 'http://localhost/siebck/api/';
export const endpointImg = 'http://localhost/siebck/public/assets/images/';*/


/*export const endpointBck = 'https://siebck.ecosysb.ecowas.int/';
export const endpointFrt = 'https://siefront.ecosysb.ecowas.int/';
export const endpoint = 'https://siebck.ecosysb.ecowas.int/api/';
export const endpointImg = 'https://siebck.ecosysb.ecowas.int/public/assets/images/';*/

/*export const endpointBck = 'https://sietogo.ecosysb.ecowas.int/';
export const endpointFrt = 'https://sie-togo.ecosysb.ecowas.int/';
export const endpoint = 'https://sietogo.ecosysb.ecowas.int/api/';
export const endpointImg = 'https://sietogo.ecosysb.ecowas.int/public/assets/images/';*/



export const  endpointBck = process.env.REACT_APP_ENDPOINT_BCK;
export const  endpointFrt = process.env.REACT_APP_ENDPOINT_FRT;
export const  endpoint = process.env.REACT_APP_ENDPOINT;
export const  endpointImg = process.env.REACT_APP_ENDPOINT_IMG;
export const  TOKEN = process.env.REACT_APP_TOKEN;



//axios.defaults.headers.common['Accept'] = 'application/json';
//axios.defaults.headers.common['Content-type'] = 'application/json';
//axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
/*axios.defaults.withCredentials = true;
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
const token = localStorage.getItem('token');
if (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    console.log(token)
}


export const apiClient = axios.create({
    baseURL: 'http://localhost/siebck',
    withCredentials: true,
})

export const setTokenLogin = apiClient.get('/sanctum/csrf-cookie')
    .then(response => {
        apiClient.post('/api/login', {
            email: 'ecowas@ecowas.int',
            password: 'password'
        }).then(response => {
            sessionStorage.setItem('loggedIn', true);
            localStorage.setItem('token', response.data.access_token);
            axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;
            //console.log('l', response)
            return response;
        }).catch(error => {
            sessionStorage.setItem('loggedIn', false);
            localStorage.removeItem('token');
            axios.defaults.headers.common['Authorization'] = 'Bearer';
        });
    });



const logout = () => {
    apiClient.post('/logout').then(response => {
        if (response.status === 204) {
            //setLoggedIn(false);
            //sessionStorage.setItem('loggedIn', false);
        }
    })
};*/

axios.defaults.headers.common = {'Authorization': `Bearer ${TOKEN}`}

export const readCountry =  () => axios.get(endpoint+'readCountry')
export const listNews =  () => axios.get(endpoint+'news')
export const listSlides =  () => axios.get(endpoint+'slides')
export const listDashInd =  () => axios.get(endpoint+'getdashbordind')
export const listIndicator =  () => axios.get(endpoint+'getindicator')
export const readNew = (id) => axios.get(endpoint+'getNew/'+id)
export const readDetBalance = (year) => axios.get(endpoint+'getdetbalancestat/'+year)
export const readSimpleBalance = (year) => axios.get(endpoint+'getsimpbalancestat/'+year)
export const readCombfgazmStat = (year) => axios.get(endpoint+'getcombfgazmstat/'+year)
export const readElecqstatStat = (year) => axios.get(endpoint+'getelecqstat/'+year)
export const readEnrbiomasseStat = (year) => axios.get(endpoint+'getenrbiomassestat/'+year)
export const readGaznaturalStat = (year) => axios.get(endpoint+'getgaznaturalstat/'+year)
export const readPetrolestat = (year) => axios.get(endpoint+'getpetrolestat/'+year)
export const readDashboardstat = (dashboard) => axios.get(endpoint+'rdashboardstat/'+dashboard)
export const readIndicatorStat = (indicator) => axios.get(endpoint+'rindicatorstat/'+indicator)
export const sendContact =  ({name, email, subject, message}) => axios.post(endpoint+'sendContact', {
    name,
    email,
    subject,
    message
})
export const hDashboardStat = () => axios.get(endpoint+'hdashboardstat')
export const indicatorKey = () => axios.get(endpoint+'indicatorkey')

