import React, {useContext} from 'react';


//import {Zoom} from "react-awesome-reveal";
import {Link} from "react-router-dom";
import {endpointImg} from "../api";
//import TextTruncate from 'react-text-truncate'; // recommend
import parse from 'html-react-parser';
import moment from 'moment';
import 'moment/locale/fr'
import MultiLingualContent from "../config/multilingualContent";
import {LanguageContext} from "../config/multilingualContext";
import {endpointFrt} from '../api/index'
import HtmlToReactParser from "html-to-react";
import Truncate from 'react-truncate-html';

moment.locale('en');
var month = 'January_February_March_April_May_June_July_August_September_October_November_December'.split('_');
moment.updateLocale('en', {months: month});




const NewsItem = (props) => {
    const {language, toggleLanguage} = useContext(LanguageContext);


    //const {slug, path, blogImage, date, msg, title, description, btnText} = props.blog;
    const {id, created_at, titleFr, titleEn, titlePr, descFr, descEn, descPr, sampleImg} = props.blog;
    const descFrParse = parse(descFr)
    return (

            <div className="col-lg-4 col-md-4">
                <div className="blog-body">
                    <div className="blog-image"
                         style={{
                             backgroundImage: `url(${endpointImg + sampleImg})`,
                             height: '250px',
                             backgroundPosition: 'center',
                             backgroundSize: 'cover'
                         }}>
                        {/*<img src={endpointImg+sampleImg} alt="text"/>*/}
                    </div>
                    <div className="mt-3 blog-text ">
                        <ul className="list-inline">
                            <li className="list-inline-item"><span>{moment(created_at).format('MMMM, DD YYYY')}</span>
                            </li>
                            {/*<li className="list-inline-item"><span>{msg}</span></li>*/}
                        </ul>
                        <div className="blog-text-title">
                            <Link className="read-more" to={'/new/' + id}>
                                {
                                    parse({
                                        'french': titleFr,
                                        'english': titleEn,
                                        'portuguais': titlePr,
                                    }[language])
                                }
                                {/*(language==='french') ? titleFr : titleEn*/}
                            </Link>
                        </div>
                        <div className="blog-text-desc">
                            <Truncate
                                lines={3}
                                dangerouslySetInnerHTML={{
                                    __html: {
                                        'french': descFr,
                                        'english': descEn,
                                        'portuguais': descPr,
                                    }[language]
                                }}
                            />

                        </div>
                        <Link to={'/new/' + id}><span className="spanCover"><MultiLingualContent
                            contentID="seemore"/></span></Link>
                    </div>
                </div>
            </div>

    );

};

export default NewsItem;