import React, {useContext, useEffect, useState} from 'react';
import {endpointImg} from "../api";
import SectionTitle from "./SectionTitle";
import {readNew} from "../functions";
import parse from 'html-react-parser';
import MultiLingualContent from "../config/multilingualContent";
import {LanguageContext} from "../config/multilingualContext";

/*
const result = (
    await Promise.all([
        fetch(sidebarUrl),
        fetch(issueUrl),
        fetch(commentsUrl)
    ])
).map((r) => r.json());

// and waiting a bit more - fetch API is cumbersome
const [sidebarResult, issueResult, commentsResult] = await Promise.all(
    result
);*/

const NewItem = ({id}) => {

    const { language, toggleLanguage } = useContext(LanguageContext);
    const [blogItem, setBlogItem] = useState({});
    const [isloading, setIsloading] = useState(false);

    useEffect(() => {
        async function fetchNew() {
            setIsloading(true);
            try {
                const response = await readNew(id);
                setBlogItem(response);
            } catch (error) {
                console.log(error);
            }
            setIsloading(false);
        }

        fetchNew();

    }, [id]);
    return (
        <div className="container news-list-items mb-5">
            <SectionTitle pageTitle={(blogItem && Object.keys(blogItem).length > 0 ) && blogItem?.titleFr}/>
            <div className="row mt-3 text-justify" >
                {isloading ?
                    <div className="d-flex justify-content-center">
                        <div className="spinner-border" role="status">
                        </div>
                    </div> :
                    ((blogItem && Object.keys(blogItem).length > 0) ?
                        <React.Fragment>
                            <div className="col-lg-12 col-md-12 col-sm-12">
                                <div className="" style={{
                                    backgroundImage: `url(${endpointImg + blogItem?.sampleImg})`,
                                    height: '350px',
                                    backgroundPosition: 'center',
                                    backgroundSize: 'cover',
                                    marginBottom: '25px',
                                    width: '100%',
                                    margin: '0 auto',
                                    borderRadius: '5px'
                                }}></div>
                            </div>

                            <div className="col-lg-12 col-md-12 col-sm-12 mt-5">
                                <div className="">
                                    {parse(
                                        {
                                            'french': blogItem?.descFr,
                                            'english': blogItem?.descEn,
                                            'portuguais': blogItem?.descPr
                                        }[language]
                                    )}
                                </div>
                            </div>

                        </React.Fragment> :
                            <React.Fragment>
                                <MultiLingualContent contentID="nodata"/>
                            </React.Fragment>
                       )
                }
            </div>
        </div>
    );
};

export default NewItem;