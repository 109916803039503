import React, {useContext, useEffect, useState} from 'react';
import SectionTitle from "./SectionTitle";
import '../assets/css/dashcontent.css'
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import MultiLingualContent from "../config/multilingualContent";
import {LanguageContext} from "../config/multilingualContext";
import {listDashInd, readCountry, readDashboard} from "../functions";
import Simpbalance from "./Simpbalance";
import Detbalance from "./Detbalance";
import Combfgazmstat from "./Combfgazmstat";
import Elecqstat from "./Elecqstat";
import Enrbiomassestat from "./Enrbiomassestat";
import Gaznaturalstat from "./Gaznaturalstat";
import Petrolestat from "./Petrolestat";
import ReactEcharts from "echarts-for-react";
import SelectPicker from "rsuite/SelectPicker";


const DashContent = () => {

    const [checked, setChecked] = useState([]);
    const [expanded, setExpanded] = useState([]);

    const {language, toggleLanguage} = useContext(LanguageContext);
    const [isloading, setIsloading] = useState(false);
    const [dashboard, setDashboard] = useState("");
    const [nodata, setNodata] = useState(true);
    const [year, setYear] = useState("");
    const [title, setTitle] = useState("");
    const [loading, setLoading] = useState(false);
    const [dashCat, setDashCat] = useState([]);
    const [dashStat, setDashStat] = useState([]);
    const [indCatItem, setIndCatItem] = useState([]);
    const [indCatItemInd, setIndCatItemInd] = useState([]);


    const [axys, setAxys] = useState([]);
    const [allData, setAllData] = useState([]);
    const [performData, setPerformData] = useState([]);
    const [indDetails, setIndDetails] = useState({});
    const [legend, setLegend] = useState([]);
    const [legendData, setLegendData] = useState([]);
    const [serie, setSerie] = useState([]);
    const [graphunit, setGraphunit] = useState([]);
    const [option, setOption] = useState({});
    const [isCompose, setIsCompose] = useState(false);

    const nodes = [{
        value: 'mars',
        label: 'Mars',
        children: [
            {value: 'phobos', label: 'Phobos'},
            {value: 'deimos', label: 'Deimos'},
        ],
    }];

    useEffect(() => {
        async function fetchListDashInd() {
            setIsloading(true);
            try {
                const response = await listDashInd();
                const {status, code, data} = await response;
                const {dashCat, indCatItem, indCatItemInd} = await data;

                setDashCat(dashCat);
                setIndCatItem(indCatItem);
                setIndCatItemInd(indCatItemInd);

                let dataA = [];
                dashCat?.map(
                    item => item['indCatItemInd']?.length > 0 && item['indCatItemInd']?.map(item2 => dataA.push({

                        "label": {
                            'french': item2.label_fr,
                            'english': item2.label_en,
                            'portuguais': item2.label_pr,
                        }[language],
                        "value": item2.id,
                        "role": {
                            'french': item.label_fr,
                            'english': item.label_en,
                            'portuguais': item.label_pr,
                        }[language]
                    }))
                );
                setPerformData(dataA);

                //console.log(dataA)
                //console.log(indCatItemInd)
            } catch (error) {
                console.log(error);
            }
            setIsloading(false);
        }

        fetchListDashInd();

    }, [language])
    const handleChangeInd = (e) => {         // separate handler for each field
        //let emailValid = e.target.value ? true : false;        // basic email validation
        setDashboard(e.target.value);
        clearData();
        //setChart(legend, axys, serie, 'null');
    }

    const handleSubmit = (e) => {

        e.preventDefault();
        fetchData();
    }

    const fetchData = async () => {
        setLoading(true);
        try {
            clearData();
            const response = await readDashboard(dashboard);
            const {data} = await response;

            const {
                chart,
                legend,
                inddetails,
                axys,
                unit,
            } = await data;


            let chartLegend = [];
            legend.forEach((item, index)=>{
                chartLegend.push(setLegendInSIteLanguage(item))
            })

            let series = [];
            if (chart.length > 0) {
                setAxys(axys);

                chart.forEach((item, index)=> {
                    series.push({
                        name: setLegendInSIteLanguage(item.name),
                        itemStyle: item.itemStyle,
                        data: item.serie,
                        type: 'bar',
                        areaStyle: {},
                    })
                })

                setChart(chartLegend, axys, series, setLegendInSIteLanguage(inddetails), setLegendInSIteLanguageUnit(unit));

            } else {
                setSerie([]);
            }


        } catch (error) {
            console.log(error);
        }

        setLoading(false);
    }

    useEffect(() => {
        if (dashboard !== "") fetchData();
    }, [language])


    const clearData = () => {
        setIndDetails({});
        setTitle("");
        setLegend([]);
        setLegendData([]);
        setAxys([]);
        setOption({});
        //setSerie([]);
    }

    const setChart = (legend, axys, myserie, title, unit) => {
        setTitle(title)
        //setTitle(dashboard)
        setSerie(myserie);


        const opt = {
            grid: {
                //top: 0,
                left: '2%',
                right: '10%',
                bottom: '10%',
                containLabel: true
            },
            title: {
                //show: serie.length === 0,
                text: title,
                textStyle: {
                    overflow: 'none',
                    fontSize: 13,
                    color: "#000"
                },
            },
            tooltip: {
                trigger: "axis",
                //formatter: timeseriesTooltip
                formatter: function (params) {
                    var colorSpan = color => '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:' + color + '"></span>';
                    var res = `${params[0].name} <br />`;
                    params.forEach(function (item, index) {
                        res += `  ${colorSpan(item.color)}  ${item.seriesName} ：${item.value} (${unit})  <br />`;
                    });
                    return res;
                },
            },
            legend: {
                bottom: 15,
                type: 'scroll',
                orient: 'horizontal',
                x: 'center',
                y: 'bottom',
                data: setLegendInSIteLanguage(legend),
                textStyle: {
                    overflow: 'none',
                    fontSize: 10,
                    color: "#000"
                },
            },
            toolbox: {
                top: 10,
                orient: 'vertical',
                x: 'right',
                show: true,
                feature: {
                    magicType: {
                        show: true,
                        type: ['line', 'bar', 'stack'],
                        title: {
                            line: "Ligne",
                            bar: "Bar",
                            stack: "Empilé",
                            tiled: "Tiled",
                        }
                    },
                    /*restore: {
                        show: true,
                        title: 'restore'
                    },*/
                    saveAsImage: {
                        show: true,
                        title: 'Enregistré',
                        pixelRatio: 2
                    }
                }
            },
            xAxis: {
                type: 'category',
                data: axys,
            },
            yAxis: {
                type: 'value',
            },

            /*yAxis: {
                type: 'value',
                splitNumber: 10,
                boundaryGap: ['0', '100%'],
                min:0,
                max:100,
                scale:true,
                splitArea : {show : true},
                label: {
                    formatter: function(value){
                        //return 100 * value / this.axis.tickPositions.last()[0] + '%';
                        return Math.floor(100 * value / 2000) + '%';
                    }
                }
            },*/
            /*yAxis: [
                {
                    type: 'value',
                    axisLabel: {
                        formatter: (value) => {
                            return Math.floor(100 * value / 2000) + '%';
                        }
                    }
                }
            ],*/
            series: myserie,

        };
        setOption(opt);
    }

    const setLegendInSIteLanguage = (label) => {
        //console.log(label);
        const myLabel = {
            'french': label.label_fr,
            'english': label.label_en,
            'portuguais': label.label_pr
        }
        return myLabel[language];
    }

    const setLegendInSIteLanguageUnit = (label) => {
        const myLabel = {
            'french': label.unit_label_fr,
            'english': label.unit_label_en,
            'portuguais': label.unit_label_pr
        }
        return myLabel[language];
    }

    function SubmitButton() {
        if (dashboard) {
            return <button className="btn btn-xs btn-primary" type="submit"><MultiLingualContent contentID="validation"/></button>
        } else {
            return <button disabled className="btn btn-xs btn-primary" type="submit"><MultiLingualContent contentID="validation"/></button>
        }
    }


    return (
        <div className="container about-list-items mb-5">
            <SectionTitle pageTitle="tboard"/>
            <form onSubmit={handleSubmit}>
                <div className="row mt-2 ">
                    <div className="col-lg-6 col-xl-6 col-md-6 col-xs-12 mt-2">
                        <SelectPicker
                            label={<MultiLingualContent contentID="tboard"/>}
                            data={performData}
                            groupBy="role"
                            style={{width: 500}}
                            defaultValue={dashboard}
                            onChange={setDashboard}
                        />
                        {/*<div className="input-group">
                            <label className="input-group-text" htmlFor="indicator"><MultiLingualContent
                                contentID="indicator"/></label>
                            <select className="form-select selectpicker" id="dashboard" name="dashboard"
                                    onChange={event => handleChangeInd(event)} required>
                                <option value="">Choose...</option>
                                {dashCat.length > 0 && dashCat?.map((item, index) =>
                                    <optgroup key={index} label={{
                                        'french': item.label_fr,
                                        'english': item.label_en,
                                        'portuguais': item.label_pr,
                                    }[language]}>
                                        {item['indCatItemInd']?.length > 0 && item['indCatItemInd'].map((item2, index2) => {

                                            return (
                                                <option key={index2} value={item2.id}>{{
                                                    'french': item2.label_fr,
                                                    'english': item2.label_en,
                                                    'portuguais': item2.label_pr,
                                                }[language]}</option>
                                            )

                                        })}
                                    </optgroup>
                                )}
                            </select>
                        </div>*/}
                    </div>
                    {/*<div className="col-lg-4 col-xl-4 col-md-4  col-xs-12 mt-2">
                        <div className="input-group">
                            <label className="input-group-text" htmlFor="annee"><MultiLingualContent
                                contentID="year"/></label>
                            <select className="form-select selectpicker" id="annee"
                                    onChange={event => setYear(event.target.value)}>
                                <option selected>Choose...</option>
                                <option value="2019">2019</option>
                                <option value="2020">2020</option>
                                <option value="2021">2021</option>
                                <option value="2022">2022</option>
                                <option value="2023">2023</option>
                            </select>
                        </div>
                    </div>*/}
                    <div className="col-lg-6 col-xl-6 col-md-6 col-xs-12 mt-2">
                        <SubmitButton/>
                    </div>
                </div>
            </form>
            <div className="row">
                <div className="about-list-items-right col-lg-12 col-xl-12 col-md-12 col-xs-12">
                    <div className="card mt-3">
                        <div className="card-body">
                            {loading ? <span></span> :
                                <div className=" mt-2">
                                    <div className="">
                                        {serie?.length > 0 ? <ReactEcharts
                                            notMerge={true}
                                            lazyUpdate={true}
                                            option={option}
                                            style={{width: "100%", height: "55vh"}}
                                        /> : <p> {
                                            {
                                                'french': 'Aucune donnée disponible !',
                                                'english': 'No data available!',
                                                'portuguais': 'Não existem dados disponíveis!'
                                            }[language]
                                        }</p>}
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DashContent;