import React from 'react';
import MoreButton from "./MoreButton";
import MultiLingualContent from "../config/multilingualContent";
import '../assets/css/sectiontitle.css'

const SectionTitle = (props) => {

    const pageTitle = props.pageTitle;
    return (
        <div className="section-title">
            <h2 className="section-title-text"><MultiLingualContent contentID={pageTitle} />
                {/*<MoreButton/>*/}
            </h2>
        </div>
    );
};

export default SectionTitle;