import React from 'react';
import '../assets/css/loading.css'


function Loading() {

    return (
        <>
            <div className="overlay show"></div>
            <div className="spanner show">
                <div className="loader"></div>
                <p>Load...</p>
            </div>
        </>
    );

}


export default Loading;