import React, {useContext} from 'react';
import {LanguageContext} from "../config/multilingualContext";

const Simpbalance = (props) => {
    const { language, toggleLanguage } = useContext(LanguageContext);
    const {configsColumn, tableStatData, configsLine, configsUnit} = props;

    return (
        <div style={{'width': '100%', 'height':'90vh', 'overflow': 'scroll'}}>
            {Object.keys(tableStatData).length > 0 ? <table className="table table-bordered stripe display mt-2"
                                                            style={{'width': '100%', 'overflow': 'scroll'}}>
                <thead>
                <tr>
                    <th className="fixed-td">{{
                        'french': configsUnit.label_fr,
                        'english': configsUnit.label_en,
                        'portuguais': configsUnit.label_pr,
                    }[language]}</th>
                    {configsColumn.length > 0 ? configsColumn?.map((item, index) =>
                        <th className="scrollable-td fixed-hd" key={index}>
                            {{
                                'french': item.label_fr,
                                'english': item.label_en,
                                'portuguais': item.label_pr,
                            }[language]}
                        </th>) : <th className="fixed-td fixed-hd left-top-td"></th>}
                    <th>TOTAL</th>
                </tr>

                </thead>
                <tbody>
                {configsLine.length > 0 && configsLine?.map((item2, index2) => {
                    let total = 0;
                    return (
                        <tr key={index2}>
                            <td className="fixed-td">{{
                                'french': item2.label_fr,
                                'english': item2.label_en,
                                'portuguais': item2.label_pr,
                            }[language]}</td>
                            {configsColumn?.map((item3, index3) => {
                                total += (tableStatData[item2.fk_data_id] && tableStatData[item2.fk_data_id][item3.id]) ? parseFloat(tableStatData[item2.fk_data_id][item3.id]) : 0
                                return (<td className="scrollable-td" key={index3}> {(tableStatData[item2.fk_data_id] && tableStatData[item2.fk_data_id][item3.id]) ? parseFloat(tableStatData[item2.fk_data_id][item3.id]).toFixed(2) : null}</td>)
                            })}
                            <td>{ (total !== 0) ? total.toFixed(2) : null }</td>
                        </tr>
                    )
                })}
                </tbody>
            </table> : <p>Aucune donnée disponible</p>
            }
        </div>
    );
};

export default Simpbalance;