import React from 'react';
import PageWrapper from "../components/PageWrapper";
import Footer from "../components/Footer";
import BalanceContent from "../components/BalanceContent";

const Balance = (props) => {
    //if (props.token) {
        return (
            <>
                <PageWrapper>
                    <div className="text-center homepage">
                        <BalanceContent />
                        <Footer />
                    </div>
                </PageWrapper>

            </>
        );
   /* } else {
        return (
            <div>You are not logged in.</div>
        )
    }*/
 
};

export default Balance;