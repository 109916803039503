import * as api from '../api'
import {hDashboardStat, indicatoKey, readElecqstatStat, readGaznaturalStat, readPetrolestat, sendContact} from "../api";

export const readCountry = async () => {
    try {
        const {data} = await api.readCountry();
        return data;
    } catch(error) {
        console.log(error)
    }
}
export const listNews = async () => {
    try {
        const {data} = await api.listNews();
        return data;
    } catch(error) {
        console.log(error)
    }
}

export const listSlide = async () => {
    try {
        const {data} = await api.listSlides();
        return data;
    } catch(error) {
        console.log(error)
    }
}

export const listDashInd = async () => {
    try {
        const {data} = await api.listDashInd();
        return data;
    } catch(error) {
        console.log(error)
    }
}

export const listIndi = async () => {
    try {
        const {data} = await api.listIndicator();
        return data;
    } catch(error) {
        console.log(error)
    }
}


export const readNew = async (id) => {
    try {
        const {data} = await api.readNew(id);
        return data;
    } catch(error) {
        console.log(error)
    }
}


export const readDetB = async (year) => {
    try {
        const {data} = await api.readDetBalance(year);
        return data;
    } catch(error) {
        console.log(error)
    }
}

export const readSimpB = async (year) => {
    try {
        const {data} = await api.readSimpleBalance(year);
        return data;
    } catch(error) {
        console.log(error)
    }
}

export const readCombfgazm = async (year) => {
    try {
        const {data} = await api.readCombfgazmStat(year);
        return data;
    } catch(error) {
        console.log(error)
    }
}
export const readElecq = async (year) => {
    try {
        const {data} = await api.readElecqstatStat(year);
        return data;
    } catch(error) {
        console.log(error)
    }
}

export const readEnrbiomasse = async (year) => {
    try {
        const {data} = await api.readEnrbiomasseStat(year);
        return data;
    } catch(error) {
        console.log(error)
    }
}

export const readGaznatural = async (year) => {
    try {
        const {data} = await api.readGaznaturalStat(year);
        return data;
    } catch(error) {
        console.log(error)
    }
}

export const readPetrole = async (year) => {
    try {
        const {data} = await api.readPetrolestat(year);
        return data;
    } catch(error) {
        console.log(error)
    }
}
export const readDashboard = async (dashboard) => {
    try {
        const {data} = await api.readDashboardstat(dashboard);
        return data;
    } catch(error) {
        console.log(error)
    }
}

export const readIndicator = async (indicator) => {
    try {
        const {data} = await api.readIndicatorStat(indicator);
        return data;
    } catch(error) {
        console.log(error)
    }
}
export const postContact = async ({name, email, subject, message}) => {
    try {
        const {data} = await api.sendContact({name, email, subject, message});
        return data;
    } catch(error) {
        console.log(error)
    }
};


export const homeDashboard = async () => {
    try {
        const {data} = await api.hDashboardStat();
        return data;
    } catch(error) {
        console.log(error)
    }
}

export const getIndicatorKey = async () => {
    try {
        const {data} = await api.indicatorKey();
        return data;
    } catch(error) {
        console.log(error)
    }
}

/*export const setTokenLog = async () => {
    try {
        return await api.setTokenLogin();
    } catch(error) {
        console.log(error)
    }
}*/

