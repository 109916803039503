import React from 'react';
import PageWrapper from "../components/PageWrapper";
import Footer from "../components/Footer";
import NewItem from "../components/NewItem";
import {useParams} from "react-router-dom";
import NewsFilter from "../components/NewsFilter";

const New = (props) => {
    let {id} = useParams();
    //if (props.token) {
        return (
            <>
                <PageWrapper>
                    <div className="Actualite">
                        <NewItem id={id}/>
                        <NewsFilter id={id}/>
                        <Footer/>
                    </div>
                </PageWrapper>

            </>
        )
    }/* else {
        return (
            <div>You are not logged in.</div>
        )
    }
}*/;

export default New;