import flag from '../assets/images/benin.png'
import slide from '../assets/images/slide.jpeg'

export const Menu  = [
    {
        id : 1,
        title: 'home',
        path: '/',
        cname: 'nav-item',
        component : 'Home'
    },
    {
        id : 3,
        title: 'tboard',
        path: '/dashboard',
        cname: 'nav-item',
        component : 'Dashboard'
    }
    ,
    {
        id : 4,
        title: 'sourcenbalance',
        path: '/balance',
        cname: 'nav-item',
        component : 'Balance'
    },
    {
        id : 5,
        title: 'indicator',
        path: '/indicator',
        cname: 'nav-item',
        component : 'Indicateur'
    },
    {
        id : 6,
        title: 'sankey',
        path: '/sankey',
        cname: 'nav-item',
        component : 'Stats'
    },
    {
        id : 7,
        title: 'sig',
        path: '/sig',
        cname: 'nav-item',
        component : 'Sig'
    }
]

export const Auth = {
    id:1,
    title: 'login',
    path: '/signin',
    cname: 'nav-item',
    component: 'Signin'
}

export const DefaultCountry = {
    id:1,
    name: 'Bénin',
    flag: flag,
    area: '100',
    population: '100',
    desc: 'desc',
    image: ''
}


export const SlideContent = {
    id:1,
    name: 'slide 1',
    image: slide,
}


export const keyIndicators = [
    {
        id:1,
        number: 85567,
        title: "Consommation d'énergie/hab. (en tep)"
    },{
        id:2,
        number: 105,
        title: "Consommation d'électricité/hab. (en Kwh)"
    },{
        id:3,
        number: 52,
        title: "Taux d'accès national à l'électricté (en %)"
    },{
        id:4,
        number: 3500,
        title: "indicateur sur le Pourcentage de ménage ayant accès aux technologie de cuisson propre"
    },

]