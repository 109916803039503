import React from 'react';
import { MapContainer, TileLayer, useMap, Marker, Popup,  } from 'react-leaflet';
import PageWrapper from "../components/PageWrapper";
import Footer from "../components/Footer";
import SigMap from "../components/SigMap";
import FluxContent from "../components/FluxContent";

const Flux = () => {
    return (

        <>
            <PageWrapper>
                <div className="text-center homepage">
                    <FluxContent />
                    <Footer/>
                </div>
            </PageWrapper>

        </>

    );
};

export default Flux;