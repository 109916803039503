import React, {useContext, useEffect, useState} from 'react';
import {SlideContent, keyIndicators} from '../menu/Menu'
import {Zoom} from "react-awesome-reveal";

import {getIndicatorKey, listSlide} from "../functions";
import {LanguageContext} from "../config/multilingualContext";

import Slider from "react-slick";
import '../assets/css/slide.css'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css"
import {endpointImg} from "../api";

var settings = {
    arrows: false,
    dots: true,
    pauseOnHover: false,
    infinite: true,
    speed: 3000,
    autoplay: true,
    fade: true,
    variableWidth: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};

const Slide = () => {
    const {language, toggleLanguage} = useContext(LanguageContext);
    const [isloading, setIsloading] = useState(false);
    const [slides, setSlides] = useState([]);
    const [allIndKey, setAllIndKey] = useState([]);

    useEffect(() => {

        async function getSlides() {
            setIsloading(true);
            try {
                const response = await listSlide()
                const indKey = await getIndicatorKey()
                //console.log(indKey)
                const {status, code, data} = await indKey;
                //console.log(data);
                setSlides(response);
                setAllIndKey(data);
            } catch (error) {
                //console.log(error);
            }
            setIsloading(false);
        }

        getSlides()
    }, [])

    return (
        <>
            {isloading ?
                <div>
                    <span className="slide mb-5"> !</span>
                </div> :
                <Slider {...settings}>
                    {slides?.length > 0 ?
                    slides.map((item, index) => {
                        return (
                            <div key={index}>
                                <div className="slide mb-5" style={{
                                    backgroundImage: `url(${endpointImg + item?.sampleImg})`,
                                }}>
                                    <div className="key-indicators">
                                        <Zoom>
                                            {allIndKey && allIndKey?.map((item, index) => (
                                                <div key={index} className="key-indicator">
                                                    <h6>{item.value}</h6>
                                                    <p>{
                                                        {
                                                            'french': item.label_fr+' ('+item.unit_label_fr+')',
                                                            'english': item.label_en+' ('+item.unit_label_en+')',
                                                            'portuguais': item.label_pr+' ('+item.unit_label_pr+')',
                                                        }[language]
                                                    }</p>
                                                </div>
                                            ))}
                                        </Zoom>

                                    </div>

                                </div>
                            </div>
                        )
                    }) :
                        <div>
                            <div className="slide mb-5" style={{
                                backgroundImage: `url(${SlideContent})`,
                            }}>
                                <div className="key-indicators">
                                    <Zoom>
                                        {allIndKey && allIndKey?.map((item, index) => (
                                            <div key={index} className="key-indicator">
                                                <h6>{item.value}</h6>
                                                <p> {
                                                    {
                                                        'french': item.label_fr+' ('+item.unit_label_fr+')',
                                                        'english': item.label_en+' ('+item.unit_label_en+')',
                                                        'portuguais': item.label_pr+' ('+item.unit_label_pr+')',
                                                    }[language]
                                                }</p>
                                            </div>
                                        ))}
                                    </Zoom>

                                </div>

                            </div>
                        </div>
                    }
                </Slider>

            }


        </>

    );
};

export default Slide;