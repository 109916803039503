import React from 'react';
import '../assets/css/footer.css'
import eco_img from '../assets/images/ECOWAS_Logo_50_100.jpg'
import MultiLingualContent from "../config/multilingualContent";

const Footer = () => {
    return (
        <>
            <div className="footer-list-partnar-bottom mt-5">
                <div className="financial_support">
                    <h5 className="mb-3"><MultiLingualContent contentID="support" /></h5>
                    <img className="logo_partnar" src={eco_img} alt="" title=""/>
                </div>
            </div>
            <div className="footer-list-items-top">
                <div className="container p-5">
                    <div className="row">

                        <div className="first-column col-lg-4 col-xl-4 col-md-4 col-xs-12 mt-2">
                            <h6><MultiLingualContent contentID="menu" /></h6>
                            <li><MultiLingualContent contentID="home" /></li>
                            <li><MultiLingualContent contentID="about" /></li>
                        </div>
                        <div className="second-column col-lg-4 col-xl-4 col-md-4 col-xs-12 mt-2">
                            <h6><MultiLingualContent contentID="uselink" /></h6>
                            <li><a target='_blank' href="https://eis.ecowas.int/"><MultiLingualContent contentID="siecedeao" /></a></li>
                            <li><a target='_blank' href="https://au-afrec.org/fr"><MultiLingualContent contentID="afrec" /></a></li>
                        </div>
                        <div className="third-column col-lg-4 col-xl-4 col-md-4 col-xs-12 mt-2">
                            <h6><MultiLingualContent contentID="socialnetwork" /></h6>
                            <li>Facebook</li>
                            <li>Twitter</li>
                        </div>
                    </div>

                </div>

            </div>


            <div className="footer-list-items-bottom">
                <h6><MultiLingualContent contentID="copyrigth" /></h6>
                <h6><MultiLingualContent contentID="term_condition" /></h6>
            </div>
        </>

    );
};

export default Footer;