import React from 'react';
import { MapContainer, TileLayer, useMap, Marker, Popup,  } from 'react-leaflet';
import PageWrapper from "../components/PageWrapper";
import Footer from "../components/Footer";
import SigMap from "../components/SigMap";

const Sig = () => {
    return (

        <>
            <PageWrapper>
                <div className="text-center homepage">
                    <SigMap />
                    <Footer/>
                </div>
            </PageWrapper>

        </>

    );
};

export default Sig;