import React from 'react';
import PageWrapper from "../components/PageWrapper";
import DashContent from "../components/DashContent";
import Footer from "../components/Footer";

const Dash = (props) => {
   // if (props.token) {
        return (
        <>
            <PageWrapper>
                <div className="text-center homepage">
                    <DashContent/>
                    <Footer />
                </div>
            </PageWrapper>

        </>
    )}
/*else {
        return (
            <div>You are not logged in.</div>
        )
    }
};*/

export default Dash;