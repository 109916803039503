import React, {useEffect, useState, useRef, useContext} from 'react';
import {Link, NavLink} from "react-router-dom";
import {listNews, readCountry} from "../functions";
import {endpointBck, endpointImg} from "../api";
import {Menu, Auth} from "../menu/Menu";
import {FaUserAlt} from 'react-icons/fa'
import { LanguageContext } from "../config/multilingualContext";
import '../assets/css/navbar.css'

import MultiLingualContent from "../config/multilingualContent";

const NavBar = () => {

    const [DefaultCountry, setDefaultCountry] = useState({})
    const { language, toggleLanguage } = useContext(LanguageContext);

    useEffect(()=> {

        const navLinksContainer = document.getElementById('navlinks-container');


        new ResizeObserver(entries => {
            if(entries[0].contentRect.width <= 900) {
                navLinksContainer.style.transition = "transform 0.3s ease-out"
            } else {
                navLinksContainer.style.transition = "none"
            }
        }).observe(document.body)

        async function fetchCountry() {
            try {
                const response = await readCountry();
                setDefaultCountry(response);
            } catch (error) {
                console.log(error);
            }
        }

        fetchCountry();

    }, [])
    const [hamburgerToggler, setHamburgerToggler] = useState(false)


    const toggleNav =(e)=> {
        setHamburgerToggler(!hamburgerToggler);
        const ariaToggle  = e.target.getAttribute("" +
            "aria-expanded") === "true" ? "false" : "true";
        e.target.setAttribute("aria-expanded", ariaToggle);
    }

    const requestLanguage =(event) => {
        toggleLanguage(event.target.value);
    }


    return (
        <nav>
            <NavLink to='/' className="nav-icon"
                     aria-label="visit homepage"
                     aria-current="page">
                {
                    DefaultCountry &&
                        <>
                    <img src={(DefaultCountry && DefaultCountry?.countryFlag ) ? endpointImg+DefaultCountry?.countryFlag : ''} alt={DefaultCountry.label_fr && DefaultCountry?.label_fr}/>
                    <span>{(DefaultCountry && DefaultCountry?.label_fr ) ? 'SIE-'+DefaultCountry?.label_fr : ''}</span>
                        </>
                }

            </NavLink>
            <div className="main-navlinks">
                <button onClick={toggleNav} type="button" className={hamburgerToggler ? "hamburger open" : "hamburger"}
                        aria-label="Toogle navigation"
                        aria-expanded="false">
                    <span></span>
                    <span></span>
                    <span></span>
                </button>
                <div id="navlinks-container" className={hamburgerToggler ? "navlinks-container open" : "navlinks-container"}>
                    {Menu && Menu?.map((item, index) => (
                        <NavLink key={index} to={item.path} aria-current="page"><MultiLingualContent contentID={item.title} /></NavLink>
                    ))}

                </div>
            </div>
            <div className="nav-language">
                <select onChange={requestLanguage} defaultValue={'english'}>
                    <option value="french">Fr</option>
                    <option value="english">En</option>
                    <option value="portuguais">Pr</option>
                </select>
            </div>

            <div className="nav-authentication">
                <a href={endpointBck}  className={Auth?.path +' sign-user'}
                         aria-label='sign in page'>
                    <FaUserAlt size={20} />
                </a>
                <div className="sign-btns">
                    <a className="signin-btn" href={endpointBck} target="_blank"><MultiLingualContent contentID={Auth?.title} /></a>
                </div>

            </div>

        </nav>
    );
};

export default NavBar;