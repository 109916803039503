import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import {MapContainer, Marker, Popup, TileLayer} from "react-leaflet";
import 'leaflet/dist/leaflet.css'
import SectionTitle from "./SectionTitle";

const SigMap = () => {
    return (
        <div className="container about-list-items mb-5">
            <SectionTitle pageTitle="sig"/>
            <div className="mt-2">
                <MapContainer center={[9.07775, 8.67746]} zoom={13} scrollWheelZoom={false}
                              style={{width: "100%", height: "100vh", zIndex:1}}>
                    <TileLayer
                        //attribution='&copy; <a href="https://maps.google.com/maps?q=9.077751,8.6774567&ll=9.077751,8.6774567&t=k&hl=en&z=7">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        //url='https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}'

                    />
                    <Marker position={[9.07775, 8.67746]}>
                        <Popup>
                            A pretty CSS3 popup. <br/> Easily customizable.
                        </Popup>
                    </Marker>
                </MapContainer>
            </div>
        </div>


    );
};

export default SigMap;