import React from 'react';
import NavBar from "./NavBar";
import '../assets/css/pagewrapper.css'
import Slide from "./Slide";

const PageWrapper = (props) => {

    return (
        <div className="wrapper">
            <NavBar/>
            <Slide/>
            <div className={props.className ? `page-body  ${props.className}` : 'page-body'}>
                {props.children}
            </div>
        </div>
    );
};


export default PageWrapper;