import React from 'react';
import PageWrapper from "../components/PageWrapper";
import Footer from "../components/Footer";

const Stats = (props) => {
    //if (props.token) {
        return (
            <>
                <PageWrapper>
                    <div className="text-center homepage">
                        <Footer/>
                    </div>
                </PageWrapper>

            </>
        )
    } /*else {
        return (
            <div>You are not logged in.</div>
        )
    }
}*/;

export default Stats;