import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'
import Home from "./pages/Home";
import './assets/css/theme.css'
import {useEffect, useState} from "react";
import Sig from "./pages/Sig";
import Dash from "./pages/Dash";
import Balance from "./pages/Balance";
import Stats from "./pages/Stats";
import Indicator from "./pages/Indicator";
import New from "./pages/New";
import {LanguageContext} from "./config/multilingualContext"
import {listIndi, setToken, setTokenLog} from "./functions";
import {setTokenLogin} from "./api";
import axios from "axios";
import Flux from "./pages/Flux";

function App() {
    var userLang = navigator.language || navigator.userLanguage;
    const [language, setLanguage] = useState("english");
    const [loggedIn, setLoggedIn] = useState(
        sessionStorage.getItem('loggedIn') === 'true' || false
    );

    function toggleLanguage(lang) {
        //setLanguage((lang) => (lang === "english" ? "english" : "french"));
        setLanguage(lang);
    }

   useEffect( () => {


        /*async function login() {
            const response = await setTokenLogin();
            if (response?.status === 204) {
                setLoggedIn(true);
                sessionStorage.setItem('loggedIn', true);
            }
        }
       const token = localStorage.getItem('token');
       if (!token) {
           login();
       }*/

    }, [])

    return (
        <LanguageContext.Provider value={{language, toggleLanguage}}>
            <Router>
                <div className="app">
                    <Routes>
                        <Route exact={true} path="/" element={<Home/>}/>
                        <Route path="/dashboard" element={<Dash/>}/>
                        <Route path="/sig" element={<Sig/>}/>
                        <Route path="/sankey" element={<Flux/>}/>
                        <Route path="/balance" element={<Balance/>}/>
                        <Route path="/indicator" element={<Indicator/>}/>
                        <Route path="/stats" element={<Stats/>}/>
                        <Route path="/new/:id" element={<New/>}/>
                        <Route path="*/*" element={<Home/>}/>
                        <Route path='*' element={<Home/>}/>
                    </Routes>
                </div>
            </Router>
        </LanguageContext.Provider>
    );
}

export default App;
