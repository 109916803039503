import MultiLingualContent from "./multilingualContent";
import React from "react";

export const translations = {
    english: {
        home: "Home",
        about: "About",
        tboard: "Dashbord",
        sourcenbalance: "Statistics & Balance",
        balance: "Statistics & Balance",
        indicator: "Indicators",
        sig:'GIS Portal',
        login: "Login",
        actu: "News",
        contact: "Contact",
        seemore: "See more",
        uselink: "Use link",
        socialnetwork: "Ours networks",
        submit: "Submit",
        year: "Year",
        nodata: "No data available",
        ind1: "Share of RE in electricity production (%)",
        ind2: 'Household access to electricity (%)',
        siecedeao: 'ECOWAS-EIS',
        sankey:'Energy flow',
        menu:'Menu',
        simplbalancedata:'Simplified balance',
        detbalancedata:'Detailed balance',
        combfgazmdata:'Mineral coal and manufactured gas statistics',
        elecqdata:'Electricity and heat statistics',
        enrbiomassedata:'Renewable energies and biomass statistics',
        gaznaturaldata:'Natural gas statistics',
        petroledata:'Oil statistics',
        afrec:'AFREC',
        copyrigth:'©2021 - 2022, All rights reserved',
        term_condition:'Terms & Conditions',
        support: "With technical support of",
        validation:"Confirm",
        name:"Name *",
        email:"Email *",
        subject:"Subject *",
        message:"Message *",
        errortext:'Please enter data',
    },
    french: {
        home: "Accueil",
        about: "A propos",
        tboard: "Tableau de board",
        sourcenbalance: "Statistiques & Bilan",
        balance: "Statistiques & Bilan",
        indicator: "Indicateurs",
        sig:'Portail SIG',
        login: "Connexion",
        actu: "Actualités",
        contact: "Contact",
        seemore: "Voir plus",
        uselink: "Lien utiles",
        socialnetwork: "Nos réseaux",
        submit: "Soumettre",
        year: "Année",
        nodata: "Aucune donnée disponible",
        ind1: "Part des EnR dans la production d'électricité (%)",
        ind2: "Taux d'accès des ménages à l'électricité (%)",
        siecedeao:'SIE-CEDEAO',
        sankey:'Flux énergétique',
        menu:'Menu',
        simplbalancedata:'Bilan agrégé',
        detbalancedata:'Bilan désagrégé',
        combfgazmdata:'Statistique:Charbon minéral et gaz manufacturé',
        elecqdata:'Statistique:Electricité et chaleur',
        enrbiomassedata:'Statistique:Energies renouvelables et biomasse',
        gaznaturaldata:'Statistique:Gaz naturel',
        petroledata:'Statistique:Pétrole',
        afrec:'AFREC',
        copyrigth:'©2021 – 2022, Tous droits réservés',
        term_condition:'Termes & Conditions Générales d’utilisation',
        support: "Avec le soutien technique de",
        validation:"Valider",
        name:"Nom *",
        email:"Email *",
        subject:"Objet *",
        message:"Message *",
        errortext:'Veuillez saisir une donnée',
    },
    portuguais: {
        home: "home",
        about: "Uma proposta",
        tboard: "Quadro de bordo",
        sourcenbalance: "Fonte e Bilan",
        balance: "Fonte e Bilan",
        indicator: "Indicadores",
        sig:'Portal SIG',
        login: "Ligação",
        actu: "Notícias",
        contact: "Contacto",
        seemore: "Ver mais",
        uselink: "Ligações úteis",
        socialnetwork: "As nossas redes",
        submit: "Enviar",
        year: "Ano",
        nodata: "Não existem dados disponíveis",
        ind1: "Quota de energias renováveis na produção de eletricidade (%)",
        ind2: "Acesso do agregado familiar à eletricidade (%)",
        siecedeao:'SIE-CEDEAO',
        sankey:'Fluxo de energia',
        menu:'Menu',
        simplbalancedata:'Balanço agregado',
        detbalancedata:'Balanço desagregado',
        combfgazmdata:'Estatísticas:Carvão mineral e gás manufaturado',
        elecqdata:'Estatísticas:Eletricidade e calor',
        enrbiomassedata:'Estatísticas:Energias renováveis e biomassa',
        gaznaturaldata:'Estatísticas:Gás natural',
        petroledata:'Estatísticas:Óleo',
        afrec:'AFREC',
        copyrigth:'©2021 - 2022, Todos os direitos reservados',
        term_condition:'Termos e condições',
        support: "Com o apoio técnico de",
        validation:"Confirmar",
        name:"Nome *",
        email:"Email *",
        subject:"Objeto *",
        message:"Mensagem *",
        errortext:'Introduzir dados',
    },
};