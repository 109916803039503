import React, {useContext} from 'react';
import {LanguageContext} from "../config/multilingualContext";

const Petrolestat = (props) => {

    const { language, toggleLanguage } = useContext(LanguageContext);
    const {configsColumn, tableStatData, configsLine} = props;

    return (
        <div style={{'width': '100%', 'height':'90vh', 'overflow': 'scroll'}}>
            {Object.keys(tableStatData).length > 0 ? <table className="table table-bordered stripe display mt-2"
                                                            style={{'width': '100%', 'overflow': 'scroll'}}>
                <thead>
                <tr>
                    <th  className="fixed-td"></th>
                    {configsColumn.length > 0 ? configsColumn?.map((item, index) =>
                        <th className="scrollable-td fixed-hd" key={index}>{
                        {
                            'french': item.label_fr,
                            'english': item.label_en,
                            'portuguais': item.label_pr,
                        }[language]}</th>) : <th className="fixed-td fixed-hd left-top-td"></th>}
                </tr>
                <tr>
                    <th className="fixed-td"></th>
                    {configsColumn.length > 0 ? configsColumn?.map((item, index) =>
                        <th className="scrollable-td fixed-hd" key={index}>{{
                            'french': item.unitlabelfr,
                            'english': item.unitlabelen,
                            'portuguais': item.unitlabelpr,
                        }[language]}</th>) : <th className="fixed-td fixed-hd left-top-td"></th>}
                </tr>
                </thead>
                <tbody>
                {configsLine.length > 0 && configsLine?.map((item2, index2) => {
                    return (
                        <tr key={index2}>
                            <td className="fixed-td">{{
                                'french': item2.label_fr,
                                'english': item2.label_en,
                                'portuguais': item2.label_pr,
                            }[language]}</td>
                            {configsColumn?.map((item3, index3) => <td className="scrollable-td" key={index3}> {tableStatData[item2.fk_data_id] ? tableStatData[item2.fk_data_id][item3.fk_data_id] : null}</td>)}
                        </tr>
                    )
                })}
                </tbody>
            </table> : <p>Aucune donnée disponible</p>
            }
        </div>
    );
};

export default Petrolestat;