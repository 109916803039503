import React from 'react';
import PageWrapper from "../components/PageWrapper";
import Footer from "../components/Footer";
import DashContent from "../components/DashContent";
import IndicatorContent from "../components/IndicatorContent";

const Indicator = (props) => {
    //if (props.token) {
    return (
        <>
            <PageWrapper>
                <div className="text-center homepage">
                    <IndicatorContent />
                    <Footer />
                </div>
            </PageWrapper>

        </>
    )}/* else {
    return (
        <div>You are not logged in.</div>
    )
}
}*/;

export default Indicator;