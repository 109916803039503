import React, {Suspense, useEffect, useState} from 'react';
//import PageWrapper from "../components/PageWrapper";
//import Dashboard from "../components/Dashboard";
//import About from "../components/About";
//import News from "../components/News";
//import Footer from "../components/Footer";
//import ContactForm from "../components/ContactForm";
import Loading from "../components/Loading";
import Slide from "../components/Slide";

const PageWrapper = React.lazy(() => import('../components/PageWrapper'));
const Dashboard = React.lazy(() => import('../components/Dashboard'));
const About = React.lazy(() => import('../components/About'));
const News = React.lazy(() => import('../components/News'));
const Footer = React.lazy(() => import('../components/Footer'));
const ContactForm = React.lazy(() => import('../components/ContactForm'));


const Home = () => {
    const [isLoading, setIsLoading] = useState(true);
//    if (props.token) {
    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false);
        }, 2000);
    }, []);



    if (isLoading) {

        return <Loading />;

    }
    return (
        <>
            <Suspense fallback={<div>Loading...</div>}>
                <PageWrapper>
                    <div className="text-center homepage">
                        <About/>
                        <Dashboard/>
                        <News/>
                        <ContactForm/>
                        <Footer/>
                    </div>
                </PageWrapper>
            </Suspense>
        </>
    );
    /* } else {
         return (
             <div>You are not logged in.</div>
         )
     }
     ;*/

};

export default Home;