import React, {useState} from 'react';
import SectionTitle from "./SectionTitle";
import MultiLingualContent from "../config/multilingualContent";
import {readDetB, readSimpB, readCombfgazm, readElecq, readEnrbiomasse, readGaznatural, readPetrole} from '../functions'
import '../assets/css/table.css'
import Detbalance from "./Detbalance";
import Simpbalance from "./Simpbalance";
import parse from "html-react-parser";
import {readGaznaturalStat, readPetrolestat} from "../api";
import Combfgazmstat from "./Combfgazmstat";
import Elecqstat from "./Elecqstat";
import Enrbiomassestat from "./Enrbiomassestat";
import Gaznaturalstat from "./Gaznaturalstat";
import Petrolestat from "./Petrolestat";
import SelectPicker from "rsuite/SelectPicker";


const BalanceContent = () => {

    const [year, setYear] = useState("");
    const [balance, setBalance] = useState("");
    const [showdata, setShowdata] = useState("");
    const [loading, setLoading] = useState(false);
    //const [data, setData] = useState([]);
    const [configsColumn, setConfigsColumn] = useState([]);
    const [configsLine, setConfigsLine] = useState([]);
    const [configsUnit, setConfigsUnit] = useState({});
    const [tableStatData, setTableStatData] = useState([]);
    const [toDayYear, setToDayYear] = useState(new Date().getFullYear());
    const [dateFilter , setDatFilter] = useState([]);

    /*const GET_BALANCE = (year) => ({
        desagrege: readDetB(year),
        agrege1: readSimpB(year),
    });*/


    const GET_BALANCE = (year, balance) => {
        switch (balance) {
            case 'desagrege':
                return readDetB(year);
            case 'agrege':
                return readSimpB(year);
            case 'combfgazm':
                return readCombfgazm(year);
            case 'elecq':
                return readElecq(year);
            case 'enrbiomasse':
                return readEnrbiomasse(year);
            case 'gaznatural':
                return readGaznatural(year);
            case 'petrole':
                return readPetrole(year);
        }
    };

    const MESSAGE ={
        error: "Une erreur s'est produite",
        success: "Opération effectuée avec succès",
        warning: "Impossible de joindre l'hôte"
    };


    const handleSubmit = async (e) => {

        e.preventDefault();
        setLoading(true);
        setShowdata(balance);
        try {
            const response = await GET_BALANCE(year, balance);
            //console.log(response)
            const {status, code, data} = await response;
            const {configsColumnAPI, configsLineAPI, tableStatDataAPI, configsUnit} = await data;

            setConfigsColumn(configsColumnAPI);
            setConfigsLine(configsLineAPI);
            setTableStatData(tableStatDataAPI);
            setConfigsUnit(configsUnit);

        } catch (error) {
            console.log(error);
        }

        setLoading(false);

    }

    const handleChangeInd = (e) => {         // separate handler for each field
        //let emailValid = e.target.value ? true : false;        // basic email validation
        setBalance(e.target.value)
    }

    const handleChangeYear = (e) => {         // separate handler for each field
        //let emailValid = e.target.value ? true : false;        // basic email validation
        setYear(e.target.value);
    }



    function SubmitButton() {
        if (year && balance) {
            return <button className="btn btn-xs btn-primary" type="submit"><MultiLingualContent contentID="validation"/></button>
        } else {
            return <button disabled className="btn btn-xs btn-primary" type="submit"><MultiLingualContent contentID="validation"/></button>
        }
    }



    for(let i = 1900; i <= toDayYear; i++) {
        dateFilter.push({ label: i, value: i })
    }

    return (

        <div className="container bilan-list-items mb-5">
            <SectionTitle pageTitle="balance"/>
            <form onSubmit={handleSubmit}>
                <div className="row mt-2 ">

                    <div className="col-lg-4 col-xl-4 col-md-4  col-xs-12 mt-3">
                        <SelectPicker
                            label={<MultiLingualContent
                                contentID="sourcenbalance"/>}
                            style={{ width: 500 }}
                            data={[{ label: <MultiLingualContent contentID="simplbalancedata"/>, value: "agrege" },
                                { label: <MultiLingualContent contentID="detbalancedata"/>, value: "desagrege" },
                                { label: <MultiLingualContent contentID="combfgazmdata"/>, value: "combfgazm" },
                                { label: <MultiLingualContent contentID="elecqdata"/>, value: "elecq" },
                                { label: <MultiLingualContent contentID="enrbiomassedata"/>, value: "enrbiomasse" },
                                { label: <MultiLingualContent contentID="gaznaturaldata"/>, value: "gaznatural" },
                                { label: <MultiLingualContent contentID="petroledata"/>, value: "petrole" },
                            ]}
                            defaultValue={balance}
                            onChange={setBalance}
                        />
                        {/*<div className="input-group">
                            <label className="input-group-text" htmlFor="indicator"><MultiLingualContent
                                contentID="sourcenbalance"/></label>
                            <select className="form-select selectpicker" id="balance" name="balance"
                                    onChange={event => handleChangeInd(event)} required>
                                <option value="">Choose...</option>
                                <option defaultValue="agrege" value="agrege"><MultiLingualContent
                                    contentID="simplbalancedata"/></option>
                                <option value="desagrege"><MultiLingualContent
                                    contentID="detbalancedata"/></option>
                                <option value="combfgazm"><MultiLingualContent
                                    contentID="combfgazmdata"/></option>
                                <option value="elecq"><MultiLingualContent
                                    contentID="elecqdata"/></option>
                                <option value="enrbiomasse"><MultiLingualContent
                                    contentID="enrbiomassedata"/></option>
                                <option value="gaznatural"><MultiLingualContent
                                    contentID="gaznaturaldata"/></option>
                                <option value="petrole"><MultiLingualContent
                                    contentID="petroledata"/></option>
                            </select>
                        </div>*/}
                    </div>
                    <div className="col-lg-4 col-xl-4 col-md-4 col-xs-12 mt-3">
                        <SelectPicker
                            label={<MultiLingualContent
                                contentID="year"/>}
                            style={{ width: 500 }}
                            data={dateFilter}
                            defaultValue={year}
                            onChange={setYear}
                        />
                        {/*<div className="input-group">
                            <label className="input-group-text" htmlFor="annee"><MultiLingualContent contentID="year"/></label>
                            <select className="form-select selectpicker" id="year" name="year"
                                    onChange={event => handleChangeYear(event)} required>
                                <option value="">Choose...</option>
                                <option defaultValue="2018" value="2018">2018</option>
                                <option value="2019">2019</option>
                                <option value="2020">2020</option>
                                <option value="2021">2021</option>
                                <option value="2022">2022</option>
                                <option value="2023">2023</option>
                            </select>
                        </div>*/}
                    </div>
                    <div className="col-lg-4 col-xl-4 col-md-4  col-xs-12 mt-3">
                        <SubmitButton/>
                    </div>
                </div>
            </form>
            <div className="row">
                <div className="bilan-list-items-bottom col-lg-12 col-xl-12 col-md-12 col-xs-12">
                    <div className="card mt-3">
                        <div className="card-body">
                            {loading ? <span></span> :
                                <div className="bs-stepper wizard-numbered mt-2">

                                    <div className="" style={{'overflow': 'auto'}}>
                                        {
                                            {
                                                'agrege': tableStatData ? <Simpbalance configsColumn={configsColumn} configsLine={configsLine} tableStatData={tableStatData} configsUnit={configsUnit} /> : null,
                                                'desagrege': tableStatData ? <Detbalance configsColumn={configsColumn} configsLine={configsLine} tableStatData={tableStatData} configsUnit={configsUnit} /> : null,
                                                'combfgazm': tableStatData ? <Combfgazmstat configsColumn={configsColumn} configsLine={configsLine} tableStatData={tableStatData} /> : null,
                                                'elecq': tableStatData ? <Elecqstat configsColumn={configsColumn} configsLine={configsLine} tableStatData={tableStatData} /> : null,
                                                'enrbiomasse': tableStatData ? <Enrbiomassestat configsColumn={configsColumn} configsLine={configsLine} tableStatData={tableStatData} /> : null,
                                                'gaznatural': tableStatData ? <Gaznaturalstat configsColumn={configsColumn} configsLine={configsLine} tableStatData={tableStatData} /> : null,
                                                'petrole': tableStatData ? <Petrolestat configsColumn={configsColumn} configsLine={configsLine} tableStatData={tableStatData} /> : null,
                                            }[showdata]
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BalanceContent;