import React, {useEffect, useState} from 'react';
import SectionTitle from "./SectionTitle";
import NewsItem from "./NewsItem";
import {listNews} from "../functions";
import '../assets/css/news.css'
import MultiLingualContent from "../config/multilingualContent";


const NewsFilter = ({id}) => {

    const [showMoreTechStack, setShowMoreTechStack] = useState(3)
    const [blogItems, setBlogItems] = useState([]);
    const [isloading, setIsloading] = useState(false);
    const [error, setError] = useState(null)

    const loadMore = () => {
        setShowMoreTechStack((prev) => prev + 3);
    }

    useEffect(() => {

        async function fetchNews() {
            setIsloading(true);
            try {

                const response = await listNews();
                let newsCopy = [...response];
                let res = newsCopy.filter(news => {
                    return news.id != id
                })


                setBlogItems(res);
                setError(null);
                //actus.find(actu => actu._id === currentId) : {title: ''};
            } catch (err) {
                console.log(err);
                setError(err);
            }
            setIsloading(false);
        }

        fetchNews();

    }, [id]);

    const getErrorView = () => {
        return (
            <div>
                Oh no! Something went wrong. {error.message}
            </div>
        )
    }

    return (
        <div className="container news-list-items mb-5">
            <SectionTitle pageTitle="Actualités"/>

            {error ? getErrorView() : <div className="row mt-3">
                {isloading ?
                    <div className="d-flex justify-content-center">
                        <div className="spinner-border" role="status">
                        </div>
                    </div> :
                    (blogItems && blogItems?.length > 0) ? blogItems?.slice(0, showMoreTechStack).map(item => <NewsItem
                            key={item.id} blog={item}/>)
                        : <div className="col-lg-4 col-md-4"><MultiLingualContent contentID="nodata"/></div>
                }
            </div>
            }

            {showMoreTechStack >= blogItems?.length ? null :
                (<div className="more-button">
                    <button className="ms-auto" onClick={loadMore}><span><MultiLingualContent
                        contentID="seemore"/></span></button>
                </div>)
            }
        </div>
    );
};

export default NewsFilter;