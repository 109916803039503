import React, {useContext, useEffect, useState} from 'react';
import SectionTitle from "./SectionTitle";
import '../assets/css/dashcontent.css'
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import MultiLingualContent from "../config/multilingualContent";
import {LanguageContext} from "../config/multilingualContext";
import {listDashInd, listIndi, readCountry, readDashboard, readIndicator} from "../functions";
import SelectPicker from 'rsuite/SelectPicker';

// (Optional) Import component styles. If you are using Less, import the `index.less` file.
import 'rsuite/SelectPicker/styles/index.css';

const IndicatorContent = () => {

    const [checked, setChecked] = useState([]);
    const [expanded, setExpanded] = useState([]);

    const {language, toggleLanguage} = useContext(LanguageContext);
    const [year, setYear] = useState("");
    const [years, setYears] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isloading, setIsloading] = useState(false);
    const [indicator, setIndicator] = useState([]);
    const [indicatorItem, setIndicatorItem] = useState([]);
    const [indicatorStat, setIndicatorStat] = useState([]);
    const [indicatorItemLabel, setIndicatorItemLabel] = useState([]);

    const [indDetails, setIndDetails] = useState([]);
    const [allData, setAllData] = useState([]);
    const [indicatorVal, setIndicatorVal] = useState("");

    const nodes = [{
        value: 'mars',
        label: 'Mars',
        children: [
            {value: 'phobos', label: 'Phobos'},
            {value: 'deimos', label: 'Deimos'},
        ],
    }];

    useEffect(() => {
        async function fectListIndi() {
            setIsloading(true);
            try {
                const response = await listIndi();
                const {status, code, data} = await response;
                const {indicator, indicatorItem} = await data;
                setIndicator(indicator);
                setIndicatorItem(indicatorItem);
            } catch (error) {
                console.log(error);
            }
            setIsloading(false);
        }

        fectListIndi();

    }, [])


    const handleSubmit = async (e) => {

        e.preventDefault();
        setLoading(true);
        try {
            const response = await readIndicator(indicatorVal);
            const {status, code, data} = await response;
            const {tableStatDataAPI} = await data;
            /*const {indDetails, allData} = tableStatDataAPI;
            setIndDetails(indDetails);
            setAllData(allData);*/

            const {allData, indicator_item, indDetails, years} = tableStatDataAPI;
            //console.log(indicator_item);
            setIndDetails(indDetails);
            setIndicatorItemLabel(indicator_item);
            setAllData(allData);
            setYears(years);

        } catch (error) {
            console.log(error);
        }

        setLoading(false);

    }

    const handleChangeInd = (e) => {         // separate handler for each field
        //let emailValid = e.target.value ? true : false;        // basic email validation
        setIndicatorVal(e.target.value);
        clearData();
    }

    const handleChangeYear = (e) => {         // separate handler for each field
        //let emailValid = e.target.value ? true : false;        // basic email validation
        setYear(e.target.value);
        clearData();
    }

    const setLegendInSIteLanguage = (label) => {
        const myLabel = {
            'french': label.label_fr,
            'english': label.label_en,
            'portuguais': label.label_pr
        }
        return myLabel[language];
    }

    const setLegendInSIteLanguage2 = (label) => {
        const myLabel = {
            'french': label.label_fr+' ('+label.unit_label_fr+')',
            'english': label.label_en+' ('+label.unit_label_en+')',
            'portuguais': label.label_pr+' ('+label.unit_label_pr+')'
        }
        return myLabel[language];
    }

    function clearData() {
        setIndDetails([]);
        setAllData([]);
    }

    function SubmitButton() {
        if (indicatorVal) {
            return <button className="btn btn-xs btn-primary" type="submit"><MultiLingualContent contentID="validation"/></button>
        } else {
            return <button disabled className="btn btn-xs btn-primary" type="submit"><MultiLingualContent contentID="validation"/></button>
        }
    }

    const data = indicator?.map(
        item => ({
            label: {
                'french': item.label_fr,
                'english': item.label_en,
                'portuguais': item.label_pr,
            }[language], value: item.id
        })
    );

    return (

        <div className="container about-list-items mb-5">
            <SectionTitle pageTitle="indicator"/>
            <form onSubmit={handleSubmit}>
                <div className="row mt-2 ">
                    <div className="col-lg-4 col-xl-4 col-md-4  col-xs-12 mt-2">

                        <SelectPicker label={<MultiLingualContent
                            contentID="indicator"/>}
                                      data={data}
                                      style={{width: 500}}
                                      defaultValue={indicatorVal}
                                      onChange={setIndicatorVal}
                                      required
                        />
                        {/*<div className="input-group">
                            <label className="input-group-text" htmlFor="indicator"><MultiLingualContent
                                contentID="indicator"/></label>


                            <select className="form-select selectpicker" id="indicator" name="indicator"
                                    onChange={event => handleChangeInd(event)} required>
                                <option value="">Choose...</option>
                                {indicator.length > 0 && indicator?.map((item, index) => {
                                    return (
                                        <option key={index} value={item.id}>{{
                                            'french': item.label_fr,
                                            'english': item.label_en,
                                            'portuguais': item.label_pr,
                                        }[language]}</option>
                                    )
                                })}

                            </select>
                        </div>*/}
                    </div>
                    {/*<div className="col-lg-4 col-xl-4 col-md-4  col-xs-12 mt-2">
                        <SelectPicker
                            label={<MultiLingualContent
                                contentID="year"/>}
                            style={{width: 500}}
                            data={[{label: "2019", value: "2019"},
                                {label: "2020", value: "2020"},
                                {label: "2021", value: "2021"},
                                {label: "2022", value: "2022"},
                                {label: "2023", value: "2023"},
                            ]}
                            defaultValue={year}
                            onChange={setYear}
                        />

                        <div className="input-group">
                            <label className="input-group-text" htmlFor="annee"><MultiLingualContent
                                contentID="year"/></label>
                            <select className="form-select selectpicker" id="annee"
                                    onChange={event => handleChangeYear(event)} required>
                                <option value="">Choose...</option>
                                <option value="2019">2019</option>
                                <option value="2020">2020</option>
                                <option value="2021">2021</option>
                                <option value="2022">2022</option>
                                <option value="2023">2023</option>
                            </select>
                        </div>
                    </div>*/}

                    <div className="col-lg-4 col-xl-4 col-md-4 col-xs-12 mt-2">
                        <SubmitButton/>
                    </div>
                </div>
            </form>
            <div className="row">
                <div className="about-list-items-right col-lg-12 col-xl-12 col-md-12 col-xs-12">
                    <div className="card mt-3">
                        <div className="card-body">
                            {loading ? <span></span> :
                                <div className="bs-stepper wizard-numbered mt-2">

                                    <div className="bs-stepper-content" style={{'overflow': 'scroll'}}>
                                        {allData?.length > 0 && <table className='table'>
                                            <thead>
                                            <tr>
                                                <th >{indDetails && setLegendInSIteLanguage(indDetails)}</th>
                                                {years?.length > 0 && years?.map((item, index) => <th
                                                    key={index}>{item}</th>)}
                                            </tr>


                                            {/*<tr><th colSpan="2">{indDetails && setLegendInSIteLanguage(indDetails)}</th> </tr>

                                            {item.id === item3.indicateurItem ? item3.value : ''}
                                            */}

                                            </thead>
                                            <tbody>

                                            {indicatorItemLabel?.length > 0 && indicatorItemLabel?.map((item, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{setLegendInSIteLanguage2(item)}</td>
                                                        {
                                                            years?.length > 0 && years?.map((yearItem, yearIndex)=> {
                                                                if(allData[yearIndex] && allData[yearIndex][item.id]) {
                                                                    return <td key={yearIndex}>{allData[yearIndex][item.id].value}</td>
                                                                }else{
                                                                    return <td></td>;
                                                                }
                                                                {/*allData[yearIndex]?.map((dataItem, dataIndex) => {

                                                                    if(dataItem.indicateurItem === item.id) {
                                                                        console.log(dataItem.indicateurItem+" "+yearIndex+" "+dataItem.value);
                                                                        return (
                                                                            <React.Fragment key={dataIndex}>
                                                                                <td key={dataIndex}>{dataItem.value}</td>
                                                                            </React.Fragment>
                                                                        )
                                                                    }
                                                                })*/}
                                                            })
                                                        }
                                                        {allData?.map((item2, index2) => {
                                                            return (
                                                                <React.Fragment key={index2}>{
                                                                    item2?.length > 0 && item2?.map((item3, index3) =>
                                                                        <td key={index3}>{item3.value}</td>
                                                                    )

                                                                }
                                                                </React.Fragment>
                                                            )
                                                        })}
                                                    </tr>
                                                )
                                            })}

                                            </tbody>
                                        </table>}
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default IndicatorContent;